"use strict";
import navigation from "./modules/navigation";
window.addEventListener("load", () => {
  SmoothScroll({
    // Время скролла 400 = 0.4 секунды
    animationTime: 700,
    // Размер шага в пикселях
    stepSize: 100,

    // Дополнительные настройки:

    // Ускорение
    accelerationDelta: 20,
    // Максимальное ускорение
    accelerationMax: 1,

    // Поддержка клавиатуры
    keyboardSupport: true,
    // Шаг скролла стрелками на клавиатуре в пикселях
    arrowScroll: 50,

    // Pulse (less tweakable)
    // ratio of "tail" to "acceleration"
    pulseAlgorithm: true,
    pulseScale: 4,
    pulseNormalize: 1,

    // Поддержка тачпада
    touchpadSupport: true,
  });

  navigation();

  const btn = document.querySelector(".to-top");

  btn.addEventListener('click', () => {
    gsap.to(window, { duration: 1, ease: "power2.out", scrollTo: 0 });
  })

  window.addEventListener("scroll", toggleBtn);

  toggleBtn();

  function toggleBtn() {
    if (window.scrollY > window.innerHeight) {
      btn.classList.add("show");
    } else {
      btn.classList.remove("show");
    }
  }
  window.addEventListener('touchmove', function(event) {
    event = event.originalEvent || event;

    if(event.scale > 1) {
        event.preventDefault();
        return false
    }
});
  
});
window.addEventListener("wheel", function(e) {
  if (e.ctrlKey) {
    e.preventDefault();
    console.log('hi')
    return false;
  }
}, { passive: false });

const body = document.querySelector('body');

  body.addEventListener('touchstart', e => {
    e.touches.length > 1 ? e.preventDefault() : null
  }, {passive: false})

// window.addEventListener('touchmove', function(event) {
//    if (event.scale !== 1) {
//     event.preventDefault();
//     return false;
//    }
//   }, {
//    passive: false
//   });
